import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Becawse you have the Freedom to Drive</h1>
    <p>Rent-a-car by the hour with no deposit.</p>
    <p>Becawse you can.</p>
    <StaticImage
      src="../images/teenagers-with-car.jpg"
      width={1920}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Rent a car by the hour, no deposit"
      style={{ marginBottom: `1.45rem` }}
    />
    <h2>INCREASED MOBILITY, INCREASED FLEXIBILITY.</h2>
    <p>Getting a car quickly can be challenge.</p>
    <p>Can’t afford the deposit? Can’t get a loan? Don’t want commitment?</p>
    <p>We focus on renters in the San Diego College Area neighborhood.
      This is an under-served area with a high density of low income individuals.</p>
    <p>We’re helping the local community by giving them the opportunity
      to rent a car with zero deposit, on an hourly basis.
      Rent a car and drive Uber with it to continue
      making a living in tough times.</p>
    <Link
        to="https://getaround.com"
        style={{
          color: `blue`,
          textDecoration: `none`,
        }}
    > Get Started Now </Link>

  </Layout>
)

export default IndexPage
